<template>
  <div v-if="render">
    <Modal v-if="this.settings.modal.modal_status" :heading="this.settings.modal.heading" :content="this.settings.modal.content" />
    <Header :settings="this.settings" />
    <Content :content="this.content" />
  </div>
</template>

<script>
import { computed } from "vue";
import axios from "axios";
import Header from "./components/Header";
import Content from "./components/Content";
import Modal from "./components/Modal";
export default {
  name: "App",
  components: {
    Header,
    Content,
    Modal,
  },

  data() {
    return {
      settings: [],
      content: [],
      menu: [],
      promo: [],
      render: false,
    };
  },

  created() {
    Promise.all([this.getSettings(), this.getContent(), this.getMenu()]).then(
      axios.spread((getSettings, getContent, getMenu) => {
        this.settings = getSettings.data.acf;
        this.content = getContent.data.acf;
        this.menu = getMenu.data.acf;
        this.promo = getMenu.data.acf.promos;
        this.render = true;
      })
    );
    
  },

  provide() {
    return {
      heroContent: computed(() => this.content),
      companyDetails: computed(() => this.settings),
      menu: computed(() => this.menu),
      promo: computed(() => this.promo),
    };
  },
  methods: {
    getSettings() {
      return axios({
        method: "get",
        url: process.env.VUE_APP_SETTINGS_ENDPOINT,
      });
    },
    getContent() {
      return axios({
        method: "get",
        url: process.env.VUE_APP_CONTENT_ENDPOINT,
      });
    },
    getMenu() {
      return axios({
        method: "get",
        url: process.env.VUE_APP_MENU_ENDPOINT,
      });
    },
  },
};
</script>
