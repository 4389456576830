<template>
  <div class="header">
    <div class="container py-3">
      <div class="row">
        <div class="col d-lg-none">
          <h1 class="logo mb-0">BELLA OLYMPIA PIZZARIA</h1>
          <p class="mb-0">
            <a :href="`tel:${settings.telephone}`">{{ settings.telephone }}</a>
          </p>
          <p class="mb-0">{{ settings.address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
};
</script>
