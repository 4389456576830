<template>
<div>
  <h2>{{ this.menu.value.menu[this.$props.name].heading }}</h2>
  <p class="ingredients">{{ this.menu.value.menu[this.$props.name].infotext }}</p>
  <table class="table table-borderless">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" class="text-right">Alm.</th>
                <th scope="col" class="text-right">Hj. bagt</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="pita in this.menu.value.menu[this.$props.name].pitas"
                :key="pita.id"
              >
                <td>
                  {{pita.number !== "" ? pita.number + ". " + pita.name : pita.name }}<br />
                  <span class="ingredients">{{ pita.ingredients }}</span>
                </td>
                <td></td>
                <td></td>
                <td class="text-right">{{ pita.normal + ",-" }}</td>
                <td class="text-right">{{ pita.homemade + ",-" }}</td>
              </tr>
            </tbody>
          </table>
          </div>
</template>

<script>
export default {
  name: "Pita",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  inject: ["menu"],

  data() {
    return {
    };
  }
};
</script>
