<template>
<div>
  <h2 id="mexicanpizzas" class="mb-0">{{ this.menu.value.menu[this.$props.name].heading }}</h2>
  <table class="table table-borderless">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Alm.</th>
                <th scope="col">D.pan</th>
                <th scope="col" >Fam.</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="pizza in this.menu.value.menu[this.$props.name].mexicanpizza"
                :key="pizza.id"
              >
                <td>
                  {{pizza.number !== "" ? pizza.number + ". " + pizza.name : pizza.name }}<br />
                  <span class="ingredients">{{ pizza.ingredients }}</span>
                </td>
                <td></td>
                <td>{{ pizza.normalprice + ",-" }}</td>
                <td>{{ pizza.deeppanprice + ",-" }}</td>
                <td class="text-right">{{ pizza.familyprice + ",-" }}</td>
              </tr>
            </tbody>
          </table>
          </div>
</template>

<script>
export default {
  name: "MExicanPizzas",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  inject: ["menu"],

  data() {
    return {
    };
  }
};
</script>
