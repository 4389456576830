<template>
<div>
  <h2 :id="this.givenGroup[0].productgroup.heading.replace(/\s+/g, '')">{{ this.givenGroup[0].productgroup.heading }}</h2>
  <p class="ingredients">{{ this.givenGroup[0].productgroup.infotext }}</p>
  <table class="table table-borderless">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col" class="text-right">Alm.</th>
      </tr>
    </thead>
    <tbody>
      <tr class="other" v-for="productGroup in this.givenGroup[0].productgroup.products" :key="productGroup.id">
        <td>
          {{productGroup.number !== "" ? productGroup.number + ". " + productGroup.name : productGroup.name }}<br />
          <span class="ingredients">{{ productGroup.ingredients }}</span>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td class="text-right">{{ productGroup.price + ",-" }}</td>
      </tr>
    </tbody>
  </table>
  <div>
  </div>
  </div>

</template>

<script>
export default {
  name: "Other",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  inject: ["menu"],

  data() {
    return {
      givenGroup: [],
    };
  },
  created() {
    this.givenGroup = this.menu.value.menu.others.filter((obj) => {
      return obj.productgroup.heading === this.$props.name;
    });
  },
};
</script>
