<template>
  <div class="content">
    <Hero />
    <CompanyDetails/>
    <Menu/>
  </div>
</template>

<script>
import Hero from "./Hero";
import CompanyDetails from "./CompanyDetails";
import Menu from "./Menu";
export default {
  name: "Content",
  props: {
   content: {
      type: Object,
      required: true,
    },
  },
  components: {
    Hero,
    CompanyDetails,
    Menu
  },
};
</script>
