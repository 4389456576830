<template>
  <div
    class="modal d-block"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{this.$props.heading}}</h5>
        </div>
        <div class="modal-body" v-html="this.$props.content"></div>
        <div class="modal-footer">
          <a
            @click="closeModal()"
            type="button"
            class="btn btn-secondary closeButton"
            >Luk</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    heading: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  methods: {
    closeModal() {
      const modal = document.querySelector(".modal");
      modal.classList.remove("d-block");
    },
  },
};
</script>
