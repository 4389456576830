<template>
  <div class="hero">
    <HeroContent />
  </div>
</template>

<script>
import HeroContent from "./HeroContent";
export default {
  name: "Hero",
  props: {},
  components: {
    HeroContent,
  },
};
</script>
