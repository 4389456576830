<template>
  <div class="heroContent">
    <div class="container py-3">
      <div class="row">
        <div class="col-12 col-lg-6 my-auto">
          <h1 class="mb-3">{{ this.heroContent.value.heading }}</h1>
          <p class="subheading text-center">
            {{ this.heroContent.value.welcome_text }}
          </p>
          <p>{{ this.heroContent.value.intro_text }}</p>
          <a
            @click="goToMenu(button.button_destination)"
            v-for="button in this.heroContent.value.knapper"
            :key="button.id"
            class="btn btn-xs mr-3"
            :class="button.button_style"
          >
            {{ button.button_text }}
          </a>
        </div>
        <div class="col-12 col-lg-6">
          <img class="img-fluid pizzaImg" :src="require('@/assets/pizza.png')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["heroContent"],
  name: "HeroContent",
  props: {},
  methods: {
    goToMenu(element) {
      var elems = document.querySelectorAll(".activeMenu");
      elems.forEach(function (el) {
        el.className = el.className.replace(/\bactiveMenu\b/, "");
      });

      const href = `${element.replace(/\s+/g, "")}`;
      const clickedElement = document.getElementById(href);
      if (href === "Frokosttilbud") {
        clickedElement.parentElement.classList.add("activeMenu");
      }
      clickedElement.scrollIntoView({ behavior: "smooth", offset: 40 });
    },
  },
};
</script>
