<template>
  <div id="menu" class="menu mt-5 mt-lg-0">
    <div class="container">
      <h1 class="text-center">MENU</h1>
      <div class="row text-center mb-5">
        <div
          v-for="message in this.menu.value.messages"
          :key="message.id"
          class="col-12"
        >
          <span>{{ message.message }}</span>
        </div>
        <div class="col-12">
          <a href="https://www.findsmiley.dk/92910" target="_blank">
            <img class="img-fluid d-block m-auto pt-3" :src="require('@/assets/Banner_gul_99x32.gif')"/>
          </a>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <a @click="goToMenu('pizzas')" class="btn btn-dark btn-xs mr-2 mb-2">
            {{ this.menu.value.menu.pizzas.heading }}
          </a>
          <a
            @click="goToMenu('mexicanpizzas')"
            class="btn btn-dark btn-xs mr-2 mb-2"
          >
            {{ this.menu.value.menu.mexicanpizzas.heading }}
          </a>
          <a
            @click="goToMenu(button.productgroup.heading)"
            class="btn btn-dark btn-xs mr-2 mb-2"
            v-for="button in this.menu.value.menu.others"
            :key="button.id"
          >
            {{ button.productgroup.heading }}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 h-100">
          <Pizzas :name="'pizzas'" />
          <h5>Vi bruger kalkun topping</h5>
        </div>
        <div class="col-12 col-md-6 h-100">
          <OtherProducts :name="'Indbagt Pizza'" />
          <MexicanPizzas :name="'mexicanpizzas'" />
          <OtherProducts :name="'Ekstra fyld'" />
          <OtherProducts :name="'Pasta'" />
          <Promos :name="'Etli ekmek'" />
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="col-6 col-md-3">
          <Promos :name="'Burger menu'" :menucard="true" />
        </div>
        <div class="col-6 col-md-3">
          <Promos :name="'Kebab menu'" :menucard="true" />
        </div>
        <div class="col-6 col-md-3">
          <Promos :name="'Dürüm menu'" :menucard="true" />
        </div>
        <div class="col-6 col-md-3">
          <Promos :name="'Kebab boks menu'" :menucard="true" />
        </div>
        <p class="text-center">
          *Ovenstående menuer er ekskl. salatmayonnaise eller remoulade. Kan
          tilkøbes for 6,-
        </p>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <OtherProducts :name="'Burger'" />
          <OtherProducts :name="'Hj. lavet burger'" />
          <OtherProducts :name="'Salater'" />
          <OtherProducts :name="'Forretter'" />
          <OtherProducts :name="'Børne menu'" />
          <Pita :name="'pita'" />
          <OtherProducts :name="'Frokost tilbud'" />
          <OtherProducts :name="'Flaske øl'" />
          <OtherProducts :name="'Varme drikke'" />
        </div>
        <div class="col-12 col-md-6">
          <OtherProducts :name="'Hj. lavet sandwich'" />
          <OtherProducts :name="'Fisk'" />
          <OtherProducts :name="'Durum rulle'" />
          <OtherProducts :name="'A la carte'" />
          <OtherProducts :name="'Bagt kartoffel'" />
          <OtherProducts :name="'Kæmpe mexicansk burger'" />
          <OtherProducts :name="'Drikkevarer'" />
          <OtherProducts :name="'Husets rød- eller hvidvin'" />
          <OtherProducts :name="'Spritus'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pizzas from "./Pizzas";
import MexicanPizzas from "./MexicanPizzas";
import OtherProducts from "./OtherProducts";
import Promos from "./Promos";
import Pita from "./Pita";

export default {
  name: "Menu",
  inject: ["menu"],
  components: {
    Pizzas,
    MexicanPizzas,
    OtherProducts,
    Promos,
    Pita,
  },
  created() {},
  methods: {
    goToMenu(element) {
      var elems = document.querySelectorAll(".activeMenu");
      elems.forEach(function (el) {
        el.className = el.className.replace(/\bactiveMenu\b/, "");
      });

      const href = `${element.replace(/\s+/g, "")}`;
      const clickedElement = document.getElementById(href);
      clickedElement.parentElement.classList.add("activeMenu");
      clickedElement.scrollIntoView({ behavior: "smooth", offset: 40 });
    },
  },
};
</script>
