<template>
  <div class="companyDetails py-4 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-2 phoneBox d-none d-lg-block"><i class="bi bi-telephone d-block">Telefon</i> <a :href="`tel:${this.companyDetails.value.telephone}`">{{this.companyDetails.value.telephone}}</a></div>
        <div class="col-3 addressBox d-none d-lg-block"><i class="bi bi-geo-alt d-block">Adresse</i> {{this.companyDetails.value.address}}</div>
        <div class="col-12 col-lg-3 openingBox"><i class="bi bi-clock d-block">Åbningstider</i><span class="d-block" v-for="openinghour in this.companyDetails.value.openinghours" :key="openinghour.id"> {{openinghour.days + ": " + openinghour.hours}} </span></div>
        <div class="col-4 openingBox subheading d-none d-lg-block">Experience great food.</div>
      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyDetails",
  inject: ["companyDetails"],
  props: {},
  components: {
  },
};
</script>
