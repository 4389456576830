<template>
  <h2>{{this.givenGroup[0].promoname}}</h2>
  <img :src="this.givenGroup[0].promomenu_img" class="d-block mx-auto mb-3 img-fluid"/>
  <div v-if="this.$props.menucard" class="container">
    
    <div class="row">
      <div class="col-8 col-md-10">
        <p class="ingredients">{{ this.givenGroup[0].ingredienser }}</p>
      </div>
      <div class="col-4 col-md-2">
        <h5>{{this.givenGroup[0].promoprice + ",-"}}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-8 col-md-10">
        <p class="ingredients" v-if="this.givenGroup[0].alt_ingredients">{{ this.givenGroup[0].alt_ingredients }}</p>
      </div>
      <div class="col-4 col-md-2">
        <h5 v-if="this.givenGroup[0].alt_promoprice">{{this.givenGroup[0].alt_promoprice + ",-"}}</h5>
      </div>
    </div>
    
    
  </div>
  <div v-else>
    <p class="ingredients text-center">{{ this.givenGroup[0].ingredienser }}</p>
    <h5 class="text-center">{{this.givenGroup[0].promoprice + ",-"}}</h5>
  </div>

</template>

<script>
export default {
  name: "Promos",
  props: {
    name: {
      type: String,
      required: true,
    },
    menucard: {
      type: Boolean
    },
  },
  inject: ["promo"],

  data() {
    return {
      givenGroup: [],
    };
  },
  created() {
    this.givenGroup = this.promo.value.filter((obj) => {
      return obj.promoname === this.$props.name;
    });
  },
};
</script>
